<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="5">1. Tạo điểm danh/nhận xét</el-col>
      <el-col :span="6">
        <el-button
          size="small"
          :loading="buttonLoadding1"
          @click="createAttendanceEvaluateManual('attendanceType')"
          type="primary"
          >Tạo điểm danh</el-button
        >
        <el-button
          size="small"
          :loading="buttonLoadding2"
          @click="createAttendanceEvaluateManual('evaluateType')"
          type="primary"
          >Tạo nhận xét ngày</el-button
        >
      </el-col>
      <el-col :span="4">
        <el-date-picker
          style="width: 150px"
          size="medium"
          v-model="dataInput.date"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd-MM-yyyy"
          placeholder="Chọn ngày"
          :clearable="false"
        ></el-date-picker>
      </el-col>

    </el-row>
    <el-row :gutter="10">
      <el-col :span="5">2. Tạo nhận xét tuần/tháng</el-col>
      <el-col :span="6">
        <el-button
          size="small"
          :loading="buttonLoadding3"
          @click="createEvaluateWeekMonthRootFromDateManual('evaludateWeek')"
          type="primary"
          >Tạo NX tuần</el-button
        >
        <el-button
          size="small"
          :loading="buttonLoadding4"
          @click="createEvaluateWeekMonthRootFromDateManual('evaludateMonth')"
          type="primary"
          >Tạo NX tháng</el-button
        >
      </el-col>

      <el-col :span="4">
        <el-date-picker
          style="width: 150px"
          size="medium"
          v-model="dataInput2.dateWeek"
          type="week"
          value-format="yyyy-MM-dd"
          format="Tuần WW"
          placeholder="Chọn tuần"
          :clearable="false"
        ></el-date-picker>
      </el-col>
      <el-col :span="4">
        <el-date-picker
          style="width: 160px"
          size="medium"
          v-model="dataInput2.dateMonth"
          type="month"
          value-format="yyyy-MM-dd"
          format="'Tháng 'MM-yyyy"
          placeholder="Chọn tháng"
          :clearable="false"
        ></el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="4">3. Xóa dữ liệu trùng</el-col>
      <el-col :span="4">
        <el-date-picker
          style="width: 150px"
          size="medium"
          v-model="dataInput1.date"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd-MM-yyyy"
          placeholder="Chọn ngày"
          :clearable="false"
        ></el-date-picker>
      </el-col>
      <el-col :span="8">
        <el-button
          type="primary"
          size="mini"
          :loading="checkAttendanceDuplicateLoadding"
          @click="checkAttendanceDuplicate()"
          >KTra điểm danh</el-button
        >
        &nbsp;&nbsp; KQ:{{ countAttendanceDuplicate }} &nbsp;
        <el-button
          type="danger"
          size="mini"
          :disabled="countAttendanceDuplicate == 0"
          :loading="deleteAttendanceDuplicateLoadding"
          @click="deleteAttendanceDuplicate()"
          >Xóa điểm danh</el-button
        >
      </el-col>
      <el-col :span="8">
        <el-button
          type="primary"
          size="mini"
          :loading="checkEvaluateDuplicateLoadding"
          @click="checkEvaluateDuplicate()"
          >KTra nhận xét</el-button
        >
        &nbsp;&nbsp; KQ:{{ countEvaluateDuplicate }} &nbsp;
        <el-button
          type="danger"
          size="mini"
          :disabled="countEvaluateDuplicate == 0"
          :loading="deleteEvaluateDuplicateLoadding"
          @click="deleteEvaluateDuplicate()"
          >Xóa nhận xét</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import OtherService from "@/services/CommonService/OtherService";
export default {
  data() {
    return {
      dataInput: {
        date: ""
      },
      dataInput1: {
        date: "",
      },
      dataInput2: {
        dateWeek: "",
        dateMonth: ""
      },
      buttonLoadding1: false,
      buttonLoadding2: false,
      buttonLoadding3: false,
      buttonLoadding4: false,
      countAttendanceDuplicate: "",
      countEvaluateDuplicate: "",
      checkAttendanceDuplicateLoadding: false,
      deleteAttendanceDuplicateLoadding: false,
      checkEvaluateDuplicateLoadding: false,
      deleteEvaluateDuplicateLoadding: false,
    };
  },
  methods: {
    // Tạo điểm danh và nhận xét theo ngày
    createAttendanceEvaluateManual(type) {
      let typeText = type === "attendanceType" ? "ĐIỂM DANH" : "NHẬN XÉT";
      if (this.dataInput.date === "") {
        this.$message({
          message: "Chọn ngày tạo " + typeText,
          type: "error",
        });
        throw null;
      }
      this.$confirm(
        "Bạn có chắc chắn muốn tạo " + typeText + " không?",
        "Thông báo!",
        {
          distinguishCancelAndClose: true,
          closeOnClickModal: false,
          confirmButtonText: "Có",
          cancelButtonText: "Không",
        }
      ).then(() => {
        type === "attendanceType" ? this.buttonLoadding1 = true : this.buttonLoadding2 = true;
        OtherService.createAttendanceEvaluateRootFromDateForSupperPlus(type, this.dataInput)
          .then((resp) => {
            this.$message({
              message: resp.data.message,
              type: "success",
            });
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.buttonLoadding1 = false;
              this.buttonLoadding2 = false;
            }, 1000);
          });
      });
    },
    createEvaluateWeekMonthRootFromDateManual(type) {
      let typeText = type === "evaludateWeek" ? "TUẦN" : "THÁNG";
      let dateInput = type === "evaludateWeek" ? this.dataInput2.dateWeek : this.dataInput2.dateMonth;

      if (dateInput === "") {
        this.$message({
          message: "Chọn "+typeText +" tạo nhận xét ",
          type: "error",
        });
        throw null;
      }
      let dataInputNew = {
        date: dateInput
      };
      this.$confirm(
        "Bạn có chắc chắn muốn tạo nhận xét " + typeText + " không?",
        "Thông báo!",
        {
          distinguishCancelAndClose: true,
          closeOnClickModal: false,
          confirmButtonText: "Có",
          cancelButtonText: "Không",
        }
      ).then(() => {
        type === "evaludateWeek" ? (this.buttonLoadding3 = true) : (this.buttonLoadding4 = true);
        OtherService.createEvaluateWeekMonthRootFromDateForSupperPlus(type,dataInputNew)
          .then((resp) => {
            this.$message({
              message: resp.data.message,
              type: "success",
            });
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.buttonLoadding3 = false;
              this.buttonLoadding4 = false;
            }, 1000);
          });
      });
    },
    checkDateDuplicate() {
      if (this.dataInput1.date === "") {
        this.$message({
          message: "Chọn ngày xóa dữ liệu trùng",
          type: "error",
        });
        throw null;
      }
    },
    checkAttendanceDuplicate() {
      this.checkDateDuplicate();
      this.checkAttendanceDuplicateLoadding = true;
      OtherService.getAttendanceDuplicateServiceForSupperPlus(this.dataInput1.date)
        .then((resp) => {
          this.countAttendanceDuplicate = resp.data.data;
        })
        .catch((err) => {
          this.$message({
            message: err.response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.checkAttendanceDuplicateLoadding = false;
          }, 500);
        });
    },
    deleteAttendanceDuplicate() {
      this.checkDateDuplicate();
      this.$confirm(
        "Bạn có chắc chắn muốn xóa điểm danh trùng không?",
        "Thông báo!",
        {
          distinguishCancelAndClose: true,
          closeOnClickModal: false,
          confirmButtonText: "Có",
          cancelButtonText: "Không",
        }
      ).then(() => {
        this.deleteAttendanceDuplicateLoadding = true;
        OtherService.deleteAttendanceDuplicateServiceForSupperPlus(this.dataInput1.date)
          .then((resp) => {
            this.$message({
              message: resp.data.message,
              type: "success",
            });
            this.checkAttendanceDuplicate();
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.deleteAttendanceDuplicateLoadding = false;
            }, 500);
          });
      });
    },
    checkEvaluateDuplicate() {
      this.checkDateDuplicate();
      this.checkEvaluateDuplicateLoadding = true;
      OtherService.getEvaluateDuplicateServiceForSupperPlus(this.dataInput1.date)
        .then((resp) => {
          this.countEvaluateDuplicate = resp.data.data;
        })
        .catch((err) => {
          this.$message({
            message: err.response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.checkEvaluateDuplicateLoadding = false;
          }, 500);
        });
    },
    deleteEvaluateDuplicate() {
      this.checkDateDuplicate();
      this.$confirm(
        "Bạn có chắc chắn muốn xóa nhận xét trùng không?",
        "Thông báo!",
        {
          distinguishCancelAndClose: true,
          closeOnClickModal: false,
          confirmButtonText: "Có",
          cancelButtonText: "Không",
        }
      ).then(() => {
        this.deleteEvaluateDuplicateLoadding = true;
        OtherService.deleteEvaluateDuplicateServiceForSupperPlus(this.dataInput1.date)
          .then((resp) => {
            this.$message({
              message: resp.data.message,
              type: "success",
            });
            this.checkEvaluateDuplicate();
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.deleteEvaluateDuplicateLoadding = false;
            }, 500);
          });
      });
    },

  },

};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
</style>
