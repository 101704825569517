var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { span: 5 } }, [
            _vm._v("1. Tạo điểm danh/nhận xét"),
          ]),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.buttonLoadding1,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.createAttendanceEvaluateManual(
                        "attendanceType"
                      )
                    },
                  },
                },
                [_vm._v("Tạo điểm danh")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.buttonLoadding2,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.createAttendanceEvaluateManual("evaluateType")
                    },
                  },
                },
                [_vm._v("Tạo nhận xét ngày")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "150px" },
                attrs: {
                  size: "medium",
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  format: "dd-MM-yyyy",
                  placeholder: "Chọn ngày",
                  clearable: false,
                },
                model: {
                  value: _vm.dataInput.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "date", $$v)
                  },
                  expression: "dataInput.date",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { span: 5 } }, [
            _vm._v("2. Tạo nhận xét tuần/tháng"),
          ]),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.buttonLoadding3,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.createEvaluateWeekMonthRootFromDateManual(
                        "evaludateWeek"
                      )
                    },
                  },
                },
                [_vm._v("Tạo NX tuần")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.buttonLoadding4,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.createEvaluateWeekMonthRootFromDateManual(
                        "evaludateMonth"
                      )
                    },
                  },
                },
                [_vm._v("Tạo NX tháng")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "150px" },
                attrs: {
                  size: "medium",
                  type: "week",
                  "value-format": "yyyy-MM-dd",
                  format: "Tuần WW",
                  placeholder: "Chọn tuần",
                  clearable: false,
                },
                model: {
                  value: _vm.dataInput2.dateWeek,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput2, "dateWeek", $$v)
                  },
                  expression: "dataInput2.dateWeek",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "160px" },
                attrs: {
                  size: "medium",
                  type: "month",
                  "value-format": "yyyy-MM-dd",
                  format: "'Tháng 'MM-yyyy",
                  placeholder: "Chọn tháng",
                  clearable: false,
                },
                model: {
                  value: _vm.dataInput2.dateMonth,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput2, "dateMonth", $$v)
                  },
                  expression: "dataInput2.dateMonth",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v("3. Xóa dữ liệu trùng"),
          ]),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "150px" },
                attrs: {
                  size: "medium",
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  format: "dd-MM-yyyy",
                  placeholder: "Chọn ngày",
                  clearable: false,
                },
                model: {
                  value: _vm.dataInput1.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput1, "date", $$v)
                  },
                  expression: "dataInput1.date",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    loading: _vm.checkAttendanceDuplicateLoadding,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkAttendanceDuplicate()
                    },
                  },
                },
                [_vm._v("KTra điểm danh")]
              ),
              _vm._v(
                "\n         KQ:" +
                  _vm._s(_vm.countAttendanceDuplicate) +
                  "  \n      "
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "mini",
                    disabled: _vm.countAttendanceDuplicate == 0,
                    loading: _vm.deleteAttendanceDuplicateLoadding,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteAttendanceDuplicate()
                    },
                  },
                },
                [_vm._v("Xóa điểm danh")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    loading: _vm.checkEvaluateDuplicateLoadding,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkEvaluateDuplicate()
                    },
                  },
                },
                [_vm._v("KTra nhận xét")]
              ),
              _vm._v(
                "\n         KQ:" +
                  _vm._s(_vm.countEvaluateDuplicate) +
                  "  \n      "
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "mini",
                    disabled: _vm.countEvaluateDuplicate == 0,
                    loading: _vm.deleteEvaluateDuplicateLoadding,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteEvaluateDuplicate()
                    },
                  },
                },
                [_vm._v("Xóa nhận xét")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }